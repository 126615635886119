import { render, staticRenderFns } from "./pool-background.vue?vue&type=template&id=54da661a&scoped=true&"
import script from "./pool-background.vue?vue&type=script&lang=ts&"
export * from "./pool-background.vue?vue&type=script&lang=ts&"
import style0 from "./pool-background.vue?vue&type=style&index=0&id=54da661a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54da661a",
  null
  
)

export default component.exports